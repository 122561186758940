<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list nav>
      <v-list-item v-if="about">
        <v-list-item-avatar size="60" tile v-if="about">
          <ImgWrapper :src="about.iconUrl" :avatar="true" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h4 wrap-text" v-text="about.name" />
        </v-list-item-content>
      </v-list-item>
      <v-skeleton-loader v-else type="list-item-avatar" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- ログアウト -->
    <template v-slot:append>
      <div class="pa-4">
        <v-btn block @click="logout"> ログアウト </v-btn>
      </div>
    </template>

    <!-- upgrade バー削除 -->
    <!-- <template v-slot:append>
      <base-item
        :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
      />
    </template> -->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapActions } from "vuex";

export default {
  name: "DashboardCoreDrawer",
  components: {
    ImgWrapper: () => import("../img/ImgWrapper.vue"),
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-food-fork-drink",
        title: "カテゴリ",
        to: "/category",
      },
      {
        icon: "mdi-food-fork-drink",
        title: "メニュー",
        to: "/",
      },
      {
        icon: "mdi-food-fork-drink",
        title: "オプション",
        to: "/option",
      },
      {
        icon: "mdi-store",
        title: "店舗情報",
        to: "/about",
      },
      {
        icon: "mdi-store",
        title: "営業設定",
        to: "/business-settings",
      },
      {
        icon: "mdi-store",
        title: "オンライン決済",
        to: "/onlinepayment",
      },
      {
        icon: "mdi-store",
        title: "特定商取引法",
        to: "/law",
      },
      {
        icon: "mdi-chart-bar",
        title: "売上管理",
        to: "/sales",
      },
      // {
      //   icon: "mdi-chart-bar",
      //   title: "Test",
      //   to: "/test",
      // },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage", "about"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
  },
  async mounted() {
    this.fetchAbout();
  },

  methods: {
    ...mapActions(["logoutFromFirebase", "fetchAbout"]),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    async logout() {
      await this.logoutFromFirebase();
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style>
@import "../../../../css/common.css";
</style>
